<template>
    <div class="menu">
        <div class="sub-menu shadow-lg rounded-lg pb-5 bg-white relative">
            <router-link v-for="item in menu" v-show="item.show" :key="item.val" class="menu-item rounded-lg active" :to="{ name: item.val }">
                {{ item.txt }}
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "SideBar",
    computed: {
        ...mapState("memberStore", ["memberData"]),
        ...mapState("permissionStore", ["isAdmin"]),
        routers() {
            return this.$route.meta.params.sideItems ? this.$route.meta.params.sideItems : [];
        },
        // 此頁是否在 menu first 中
        inMenuFirst() {
            const index = this.menus.first.findIndex((i) => i.value === this.showComponent);
            return index !== -1;
        },
        menu() {
            return [
                {
                    txt: "等待審核",
                    val: "wait_for_check",
                    show: this.checkPermission("wait_for_check"),
                },
                {
                    txt: "通過審核紀錄",
                    val: "checked",
                    show: this.checkPermission("checked"),
                },
                {
                    txt: "未通過審核紀錄",
                    val: "not_allow",
                    show: this.checkPermission("not_allow"),
                },
            ];
        },
    },
    methods: {
        checkPermission(key) {
            if (this.isAdmin) {
                return true;
            }
            if (this.routers.includes(key)) {
                return true;
            }
            return false;
        },
    },
    mounted() {
        this.$nextTick(() => {
            const idx = this.menu.findIndex((i) => i.show === true);
            if (idx !== -1) {
                this.$router.push({ name: this.menu[idx].val });
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.menu {
    margin-right: 10px;
    .sub-menu {
        min-width: 248px;
        background: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 10px 15px;
        .menu-item {
            width: 100%;
            height: 40px;
            text-align: left;
            font-size: 14px;
            padding: 20px 15px;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 10px 0;
            &.router-link-active {
                background: #ff583314;
            }
        }
    }
    .btn-add {
        margin-top: 12px;
    }
}
</style>
